

import React, { use, useEffect, useMemo, useState } from "react";

import { useDropzone } from "react-dropzone";
import "./App.css";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
export default function New(
  {tilte}
) {
  const [state, setState] = React.useState({} );
  const [loading, setLoading] = React.useState(true);



  const [files, setFiles] = React.useState([] );
  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    maxSize: 19548576,

    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const fileList = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));
 
  async function save() {
  
  }

  return (
    <div className="p-5  bg-gray-100 dark:bg-[#0A1A31]" dir="rtl">
      


     <>
        {fileList}
        <div className="container max-w-6xl  p-5 h-36">
          <h1>
            {tilte}
          </h1>
          <div {...getRootProps({ style: { ...style, flexDirection: 'column' } })}>
            <input {...getInputProps()} />
            <p>قم بسحب وإفلات بعض الملفات هنا، أو انقر لتحديد الملفات</p>
          </div>
        </div>
       
      </>
    </div>
  );
}
