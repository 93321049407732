import logo from "./logo.svg";
import "./App.css";
import New from "./new";
import Swal from "sweetalert2";

function App() {
  return (
    <div className="App">
      <header class="pb-6 bg-white lg:pb-0">
        <div class=" mx-auto max-w-7xl ">
          <nav class="flex items-center justify-between h-16 lg:h-20 bg-white border-b border-gray-200 ">
            <div class="flex-shrink-0 sm:px-6 lg:px-8">
              <img
                class="w-auto h-8 lg:h-10"
                src="/LogoCBL.png"
                alt="CBL Logo"
              />
            </div>
          </nav>
        </div>
      </header>
      <div class="bg-gray-50">
        <div class="">
          <div class="py-12 lg:py-20">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
              <div
                class="flex items-center w-[100vw]  justify-center text-center"
              >
                <h1 class="text-3xl w-full font-bold text-gray-900 text-center">
                  طلب إلغاء حساب المحفظة
                </h1>
              </div>
            </div>
          </div>
          <form 
          //stop  refresh 
          onSubmit={(e) => {
            e.preventDefault();
            Swal.fire({
              title:"تم ارسال الطلب بنجاح",
              text: "سيتم مراجعة الطلب والرد عليك في اقرب وقت ممكن",
              icon: "success"
            });
          }}
          >
            
          
            <div class="  flex justify-center  mx-auto " dir="rtl">
            <div class="grid gap-6 mb-6 md:grid-cols-2  w-[80vw]  " dir="rtl">
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                 الاسم الاول
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                
                  required
                />
              </div>
              <div>
                <label
                  for="last_name"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                اسم اب
                </label>
                <input
                  type="text"
                  id="last_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                 
                  required
                />
              </div>
          
              <div>
                <label
                  for="phone"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                 اسم عائلة
                </label>
                <input
                     type="text"
                  id="phone"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                
               
                  required
                />
              </div>
        
              <div class="col-span-2">
                <label
                  for="visitors"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  رقم الهوية 
                </label>
                <select
                 
                  class="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
               
                  
                >
                  <option ></option>
                 <option value="البطاقة الشخصية">البطاقة الشخصية</option><option value="جواز السفر">
                  جواز السفر
                  </option>
                  </select>

              </div>
           
           
            <New  tilte={'ارفع الوجه الامامي لصورة هوية التعريف *'}/>
            <br></br>
            <New  tilte={'ارفع الوجه الخلفي لصورة هوية التعريف *الحجم الاقصى للصورة هو 2 MB، يسمح فقط بالامتدادات jpg و png'}/>
            <br></br>
            <div class="col-span-2">
                <label
               
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
             رقم الهاتف
                </label>
                <input
                  type="text"
                  id="company"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
           
                  required
                />
              </div>
              <br></br>
              <div class="col-span-2">
                <label
               
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
   ما سبب إغلاق حساب المحفظة!
                </label>
                <input
                  type="text"
                  id="company"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
           placeholder="ما سبب إغلاق حساب المحفظة!*"
                  required
                />
              </div>
              <div class="flex items-start mb-6">
              <div class="flex items-center h-5">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300  dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  required
                />
              </div>
              <label
                for="remember"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                أوافق على{" "}
                <a
                  href="#"
                  class="text-blue-600 hover:underline dark:text-blue-500"
                >
                  شروط الخدمة
                </a>
                .
              </label>
            </div>
            <div class="col-span-2">
            <button
              type="submit"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              اوافق على جميع البيانات / قدم الطلب

            </button>
              </div>
           
            </div>
            </div>
            <br></br>
          </form>
        </div>
      </div>
      
      <br></br>
    </div>
  );
}

export default App;
